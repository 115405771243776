
































import { Component, Vue } from 'vue-property-decorator';
import { readMedications } from '@/store/medications/getters';
import { dispatchGetMedications } from '@/store/medications/actions';

@Component
export default class Medications extends Vue {
  public async mounted() {
    await dispatchGetMedications(this.$store);
  }

  get medications() {
    return readMedications(this.$store);
  }

  private getIcon(icon: string) {
    return require('@/assets/medications/' + icon + '.png');
  }
}
